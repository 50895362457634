<template>
  <div class="px-4 md:px-6 relative overflow-hidden flex flex-col">
    <!-- <div class="header-space" /> -->

    <!-- loading -->
    <LazyUILanding class="-mx-4 md:-mx-6" />

    <!-- v2 info -->
    <!-- <section class="fixed bottom-[6rem] md:bottom-4 right-4 z-10 flex items-center justify-center">
      <button class="rounded-full py-2 px-4 bg-orange-500 text-white shadow-lg shadow-orange-500/20"
        @click="showPane = 'hello'">
        {{ $t('intro.v2Info') }}
      </button>
    </section> -->

    <!-- admin says -->
    <section class="grid place-content-center text-white h-[25rem]">
      <div class="max-w-[40rem] rounded-2xl p-4 bg-zinc-700/50 shadow-xl shadow-zinc-800/50">
        <div class="flex items-start gap-2 mb-8">
          <img loading="lazy" src="https://jasonxddd.me:9000/image/1701747118927_head.png" class="-mt-16 h-24 w-24 rounded-full object-cover" />
          <h2 class="text-xl font-bold text-zinc-300">{{ $t('intro.founder') }}</h2>
          <FlagIcon class="ml-auto size-8 text-white/50" />
        </div>

        <p class="text-zinc-400 text-lg">
          {{ $t('intro.say') }}
        </p>
      </div>
    </section>

    <!-- eye-catching features -->
    <section class="w-full md:w-[90%] mx-auto flex flex-col items-center gap-4 md:gap-8 text-white">
      <div class="w-full p-8 rounded-3xl bg-zinc-700/50 overflow-hidden">
        <div class="flex flex-col gap-2 text-left md:text-center mb-8">
          <h2 class="font-black text-5xl">{{ $t('intro.titleSeries') }}</h2>
          <span class="text-zinc-400">{{ $t('intro.subtitleSeries') }}</span>
          <p class="mt-4 text-zinc-200 whitespace-pre-line">{{ $t('intro.infoSeries') }}</p>
        </div>

        <div class="relative md:-mb-8 h-auto md:h-[20rem]">
          <img
            loading="lazy"
            src="https://jasonxddd.me:9000/image/about/series.png"
            class="md:absolute top-0 rounded-3xl object-cover shadow-xl shadow-black/50"
          />
          <div class="hidden md:block absolute top-0 w-full h-full bg-gradient-to-t from-black/70 to-transparent" />
        </div>
      </div>

      <div class="w-full grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8">
        <div class="p-8 rounded-3xl bg-gradient-to-r from-purple-500 to-pink-500 overflow-hidden">
          <h2 class="font-black text-3xl">{{ $t('intro.fnTitleSeries') }}</h2>
          <p class="mt-4 text-zinc-200">{{ $t('intro.fnInfoSeries') }}</p>

          <div class="mt-12 mb-[-10rem] h-[40rem] w-fit mx-auto rounded-3xl overflow-hidden border-8 border-black/30 shadow-xl shadow-black/50">
            <img loading="lazy" src="https://jasonxddd.me:9000/image/about/series-1.gif" class="w-full h-full object-cover object-top" />
          </div>
        </div>
        <div class="p-8 rounded-3xl overflow-hidden bg-gradient-to-r from-cyan-500 to-blue-500">
          <h2 class="font-black text-3xl">{{ $t('intro.fnTitleDetail') }}</h2>
          <p class="mt-4 text-zinc-200">{{ $t('intro.fnInfoDetail') }}</p>

          <div class="mt-12 mb-[-10rem] h-[40rem] w-fit mx-auto rounded-3xl overflow-hidden border-8 border-black/30 shadow-xl shadow-black/50">
            <img loading="lazy" src="https://jasonxddd.me:9000/image/about/series-2.gif" class="w-full h-full object-cover object-top" />
          </div>
        </div>
      </div>

      <div class="w-full p-8 rounded-3xl bg-zinc-700/50 overflow-hidden flex flex-col md:flex-row items-start md:items-center relative h-[35rem]">
        <div class="flex flex-col gap-2 w-full md:w-[22rem]">
          <h2 class="font-black text-5xl whitespace-pre-line">{{ $t('intro.titleDeck') }}</h2>
          <span class="text-zinc-400">{{ $t('intro.subtitleDeck') }}</span>
          <p class="mt-4 text-zinc-200 whitespace-pre-line">{{ $t('intro.infoDeck') }}</p>
        </div>

        <img
          src="https://jasonxddd.me:9000/image/about/deck.png"
          class="mt-8 md:absolute top-0 left-[25rem] rounded-3xl object-cover shadow-xl shadow-black/50"
        />
      </div>

      <div class="w-full grid grid-cols-1 md:grid-cols-5 gap-4 md:gap-8">
        <div
          class="col-span-full md:col-span-3 p-8 rounded-3xl overflow-hidden relative min-h-[30rem] flex flex-col bg-gradient-to-r from-sky-500 to-indigo-500"
        >
          <h2 class="font-black text-3xl">{{ $t('intro.fnTitleBuilder') }}</h2>
          <p class="my-4 text-zinc-200">{{ $t('intro.fnInfoBuilder') }}</p>

          <div class="absolute inset-0" style="-webkit-mask-image: linear-gradient(to bottom, transparent 20%, #000000)">
            <img loading="lazy" src="https://jasonxddd.me:9000/image/about/deck-2.gif" class="w-full h-full object-cover object-bottom" />
          </div>
        </div>
        <div
          class="col-span-full md:col-span-2 p-8 rounded-3xl overflow-hidden relative min-h-[30rem] flex flex-col justify-end bg-gradient-to-r from-pink-500 to-rose-500"
        >
          <h2 class="text-right font-black text-3xl">{{ $t('intro.fnTitleGroup') }}</h2>
          <p class="text-right mt-4 text-zinc-200">{{ $t('intro.fnInfoGroup') }}</p>

          <div class="absolute inset-0" style="-webkit-mask-image: linear-gradient(to left top, transparent 20%, #000000)">
            <img loading="lazy" src="https://jasonxddd.me:9000/image/about/deck-1.gif" class="w-full h-full object-cover object-top" />
          </div>
        </div>
      </div>

      <div class="w-full grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8">
        <div
          class="p-8 rounded-3xl overflow-hidden grid place-content-center relative h-[28rem] bg-image"
          :style="{
            'background-image': 'url(https://jasonxddd.me:9000/image/about/social.png)'
          }"
        >
          <div class="flex items-center flex-col gap-2">
            <h2 class="font-black text-5xl">{{ $t('intro.titleSocial') }}</h2>
            <span class="text-zinc-400">{{ $t('intro.subtitleSocial') }}</span>
            <p class="text-center mt-4 text-zinc-200 whitespace-pre-line">{{ $t('intro.infoSocial') }}</p>
          </div>
        </div>

        <div
          class="p-8 rounded-3xl overflow-hidden"
          style="
            background-image: radial-gradient(at 100% 100%, rgba(170, 255, 0, 0.47), transparent),
              radial-gradient(at 0% 0%, rgba(52, 59, 202, 1), rgba(162, 120, 182, 1) 50%, rgb(220, 135, 90));
          "
        >
          <div class="flex flex-col gap-2 text-left md:text-center mb-8">
            <h2 class="font-black text-5xl">{{ $t('intro.titleTool') }}</h2>
            <span class="text-zinc-900">{{ $t('intro.subtitleTool') }}</span>
            <p class="text-center mt-4 text-zinc-200 whitespace-pre-line">{{ $t('intro.infoTool') }}</p>
          </div>

          <div class="grid grid-cols-2 md:grid-cols-3 gap-4">
            <div class="rounded-2xl p-4 text-white bg-zinc-600/50" v-for="work in workshops" :key="work">
              <CubeIcon class="size-8" />
              <span class="font-bold">{{ work }}</span>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- faq -->
    <section class="md:w-[75%] mx-auto text-white">
      <h2 class="text-center text-3xl font-black mt-12 mb-8">{{ $t('intro.often') }}</h2>
      <div class="w-full flex flex-col gap-2">
        <div
          class="p-2 rounded-xl hover:bg-zinc-800 bg-zinc-800/80 shadow shadow-zinc-800/50 cursor-pointer"
          v-for="(item, id) in faqs"
          :key="'faq-' + id"
          @click="item.show = !item.show"
        >
          <div class="flex items-center gap-2">
            <QuestionMarkCircleIcon class="flex-none size-8" />
            <h3 class="text-lg font-bold">{{ item.q }}</h3>
            <button class="ml-auto p-1 rounded-full bg-zinc-900/50 hover:bg-zinc-900">
              <ChevronDownIcon
                class="size-6 default-transition"
                :class="{
                  'rotate-180': item.show
                }"
              />
            </button>
          </div>
          <p
            class="ml-4 text-zinc-400 default-transition overflow-hidden article-html"
            :class="{
              'max-h-0 opacity-0 overflow-hidden mt-0': !item.show,
              'mt-4': item.show
            }"
            v-html="item.a"
          />
        </div>
      </div>
    </section>

    <!-- special thanks -->
    <section class="w-full md:w-[75%] mx-auto text-white">
      <h2 class="text-center text-3xl font-black mt-12 mb-8">{{ $t('intro.superthanks') }}</h2>
      <div class="p-2 flex md:justify-center gap-4 md:gap-6 overflow-x-auto overflow-y-hidden snap-x">
        <img
          v-for="partner in partners"
          :key="partner.name"
          @click="selectPartner = partner"
          :src="partner.image"
          alt=""
          class="flex-none rounded-lg w-32 h-32"
          :class="{
            'ring-4': partner.name === selectPartner.name
          }"
        />
      </div>
      <div class="p-8 rounded-xl bg-zinc-800/50 mt-4 flex flex-col md:flex-row items-center gap-4">
        <img loading="lazy" :src="selectPartner.image" alt="" class="rounded-xl w-40 h-40" />
        <div class="flex flex-col gap-4">
          <h3 class="text-center md:text-left text-3xl font-black text-zinc-200">{{ selectPartner.name }}</h3>
          <div class="flex md:justify-start justify-center items-center gap-2">
            <button class="flex items-center gap-2 bg-cyan-600 text-white rounded-full py-1 px-2" v-for="link in selectPartner.link" :key="link.name">
              <a target="_blank" :href="link.link">{{ link.name }}</a>
            </button>
          </div>
          <p class="text-zinc-400 article-html">{{ selectPartner.description }}</p>
        </div>
      </div>
    </section>

    <!-- other links -->
    <section class="w-full md:w-[75%] mx-auto text-white">
      <h2 class="text-center text-3xl font-black mt-12 mb-8">{{ $t('intro.links') }}</h2>
      <div class="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-8 md:gap-4">
        <div class="flex flex-col gap-4">
          <h3 class="font-bold text-lg">{{ $t('intro.fnLink') }}</h3>

          <NuxtLink
            to="/policy"
            class="p-2 rounded-xl text-zinc-200 hover:bg-zinc-800 bg-zinc-800/80 shadow shadow-zinc-800/50 cursor-pointer flex items-center gap-2"
          >
            <LinkIcon class="flex-none size-8" />
            <span class="text-lg"> {{ $t('intro.policy') }} </span>
          </NuxtLink>

          <NuxtLink
            to="/eula"
            class="p-2 rounded-xl text-zinc-200 hover:bg-zinc-800 bg-zinc-800/80 shadow shadow-zinc-800/50 cursor-pointer flex items-center gap-2"
          >
            <LinkIcon class="flex-none size-8" />
            <span class="text-lg"> {{ $t('intro.eula') }} </span>
          </NuxtLink>

          <a
            href="https://jasonxddd.me:7701/status/bottleneko"
            target="_blank"
            class="p-2 rounded-xl text-zinc-200 hover:bg-zinc-800 bg-zinc-800/80 shadow shadow-zinc-800/50 cursor-pointer flex items-center gap-2"
          >
            <LinkIcon class="flex-none size-8" />
            <span class="text-lg"> {{ $t('intro.status') }} </span>
          </a>
        </div>
        <div class="flex flex-col gap-4">
          <h3 class="font-bold text-lg">{{ $t('intro.outLink') }}</h3>

          <a
            class="p-2 rounded-xl text-zinc-200 hover:bg-zinc-800 bg-zinc-800/80 shadow shadow-zinc-800/50 cursor-pointer flex items-center gap-2"
            href="https://ws-tcg.com"
            target="_blank"
          >
            <LinkIcon class="flex-none size-8" />
            <span class="text-lg"> Weiβ Schwarz </span>
          </a>

          <a
            href="https://yuyu-tei.jp"
            target="_blank"
            class="p-2 rounded-xl text-zinc-200 hover:bg-zinc-800 bg-zinc-800/80 shadow shadow-zinc-800/50 cursor-pointer flex items-center gap-2"
          >
            <LinkIcon class="flex-none size-8" />
            <span class="text-lg"> 遊々亭 </span>
          </a>
        </div>
        <div class="flex flex-col gap-4">
          <h3 class="font-bold text-red-500 text-lg">{{ $t('intro.recovery') }}</h3>

          <button
            @click="doClear"
            class="p-2 rounded-xl text-zinc-200 hover:bg-zinc-800 bg-zinc-800/80 shadow shadow-zinc-800/50 cursor-pointer flex items-center gap-2"
          >
            <LinkIcon class="flex-none size-8 text-red-700" />
            <span class="text-lg text-red-500"> {{ $t('intro.resetDeck') }} </span>
          </button>

          <button
            @click="$pwa.updateServiceWorker()"
            class="p-2 rounded-xl text-zinc-200 hover:bg-zinc-800 bg-zinc-800/80 shadow shadow-zinc-800/50 cursor-pointer flex items-center gap-2"
          >
            <LinkIcon class="flex-none size-8 text-red-700" />
            <span class="text-lg text-red-500"> {{ $t('intro.forceReload') }} </span>
          </button>
        </div>
      </div>
    </section>

    <!-- bg-gradient-to-r from-violet-500 to-fuchsia-500 -->
    <NuxtLink
      to="/login"
      class="rounded-2xl w-full md:w-[75%] mx-auto mt-12 h-40 grid place-content-center bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 default-transition"
    >
      <p class="px-8 text-xl font-bold text-white text-center whitespace-pre-line md:whitespace-normal">
        {{ $t('intro.joinUs') }}
      </p>
    </NuxtLink>

    <TransitionCollapseUp>
      <UIPane v-if="showPane === 'hello'" @close="showPane = null">
        <template #title>
          <div class="flex items-center gap-2">
            <h2 class="font-bold">歡迎你來到 貓罐子 V2 !!</h2>
          </div>
        </template>

        <section class="p-4 text-zinc-300 leading-loose flex flex-col gap-4">
          <img loading="lazy" class="mx-auto w-[80%] m-4 rounded-2xl" src="/images/demo.png" alt="" />
          <p>首先感謝您來到這裡，貓罐子 V2 已經正式推出，以下為您帶來重點的更動項目：</p>
          <ul class="list-disc list-inside">
            <li>最佳適配瀏覽器：Chrome v120，解析度為：1920*1080</li>
            <li>最佳適配手機：Android 14， iOS 17</li>
          </ul>

          <p>請先閱讀這篇文章來了解新版內容</p>

          <a
            class="my-4 mx-auto md:w-60 text-center rounded-xl bg-cyan-500 hover:bg-cyan-400 text-white px-2 py-1"
            href="https://jasonxddd.notion.site/V2-56de4919639442f4af617ef3cb0b89fc"
            target="_blank"
            >V2 新版功能介紹</a
          >

          <p class="text-center">
            表單連結：
            <a class="text-cyan-500" href="https://forms.gle/YbXfx7g64YDbWFwE6">https://forms.gle/YbXfx7g64YDbWFwE6</a>
            <br />
            Discord：
            <a class="text-cyan-500" href="https://discord.gg/GKuxTzpB2A">https://discord.gg/GKuxTzpB2A</a>
          </p>
        </section>
      </UIPane>
    </TransitionCollapseUp>
  </div>
</template>

<script setup>
import { QuestionMarkCircleIcon, ChevronDownIcon, LinkIcon, CubeIcon } from '@heroicons/vue/24/outline'
import { FlagIcon } from '@heroicons/vue/24/solid'
import { api } from '@/constants/api'

const { showPane } = storeToRefs(usePreferenceStore())
const { resetCardDeck, resetCardDeckField } = useDeckStore()

const route = useRoute()
const { t } = useNuxtApp().$i18n
const { messageConfirm } = useSwal()

const faqs = ref([
  {
    q: '貓罐子是什麼？',
    a: '貓罐子是一個線上的卡牌組牌工具，提供各種便利的功能，讓您可以輕鬆的組牌、分享牌組、查詢卡片等等。\n目前專為 Weiβ Schwarz 卡牌遊戲製作。',
    show: false
  },
  {
    q: '是否有手機版可以用？',
    a: `目前貓罐子以全網站式開發，但您能可以透過以下方式加入至主畫面：
  
  1. 在裝置上開啟原生瀏覽器並進入貓罐子網站
  2. Android 裝置點選 [安裝]。 iPhone 裝置輕觸網址列右上方的「分享」圖示
  3. 找出並輕觸「加入主畫面」並按照畫面上的指示操作
  4. 在手機桌面即可出現貓罐子應用程式
  
  相關教學 <a target="_blank" href="https://support.google.com/chrome/answer/9658361?hl=zh-Hant&co=GENIE.Platform%3DiOS">使用漸進式網頁應用程式</a>`,
    show: false
  },
  {
    q: '我的貓罐子打不開或是轉圈圈？',
    a: '請您先確認處於網路良好狀態，並嘗試著重新開啟網站/應用程式，\n如仍然失敗，請立即與作者罐罐聯絡，很有可能伺服器冒煙不乖了><',
    show: false
  },
  {
    q: '我是新手，我要怎麼開始呢？',
    a: `由於貓罐子提供很多功能，您可以首先到 "系列卡表" 尋找喜愛的系列查看，
  卡片會有中文翻譯/機器翻譯，點選加入按鈕進行牌組製作。
  或是您可以到社群上找大家或是官方賽事的牌組來複製。
  
  如果您對此仍不熟悉，歡迎至貓罐子 Discord 頻道讓大家一起幫你解答：<a target="_blank" href="https://discord.gg/QMtPcXrdBq">Discord</a>`,
    show: false
  },
  {
    q: '我要怎麼加入英雄會員呢？',
    a: '英雄榜的會員，可以到工作坊找到 "加入英雄榜" 按鈕。\n完成贊助後到粉專與我們聯繫，會幫您處理後續動作。',
    show: false
  },
  {
    q: '是否有其他資源？',
    a: '目前貓罐子搜集來自<a target="_blank" href="https://ws-tcg.com">WS官方</a>以及<a target="_blank" href="https://yuyu-tei.jp">遊遊亭</a>的價格資訊，以及強大的貓罐子管理群資源。\n如果您有資源想與我們分享或商業合作，請與我聯繫！',
    show: false
  },
  {
    q: '貓罐子與武士道',
    a: '貓罐子提供的 Weiβ Schwarz 卡牌資訊以及卡圖，皆為<a target="_blank" href="https://ws-tcg.com">武士道官方</a>資料，與貓罐子本身無任何關係。\n若卡片資訊有相關侵權問題，則會下架其內容，貓罐子僅為提供輔助工具進行服務。',
    show: false
  },
  { q: '其他問題與我們態度', a: '保持正向、保持快樂、持續改善。\n無論您有任何問題、許願、想法...等等，歡迎您與我們討論！', show: false }
])

const workshops = computed(() => [...['官方牌組', '上傳至官方', '匯出圖片', '匯出檔案', '匯出賽用表'].map(e => t('intro.' + e)), 'More...'])

// action: doClear
const doClear = async () => {
  const { value } = await messageConfirm(t('deckBuilder.clearDeck'), t('deckBuilder.clearDeckInfo'))
  if (value) {
    resetCardDeck()
    resetCardDeckField()
  }
}

// fetch data
const partners = ref([])
const selectPartner = ref({})
const { data } = useLazyFetch(api['partner'](), {
  server: false
})

watch(data, () => {
  partners.value = data.value || []
  selectPartner.value = data.value[0] || {}
})

onMounted(() => {
  setTimeout(() => {
    if (route.query.hello) showPane.value = 'hello'
  }, 500)
})
</script>
